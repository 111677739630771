export * from './alert.service';
import { AlertService } from './alert.service';
export * from './auditTrail.service';
import { AuditTrailService } from './auditTrail.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './deviceMonitoring.service';
import { DeviceMonitoringService } from './deviceMonitoring.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './tracking.service';
import { TrackingService } from './tracking.service';
export * from './userAccount.service';
import { UserAccountService } from './userAccount.service';
export const APIS = [AlertService, AuditTrailService, DefaultService, DeviceMonitoringService, ReportsService, TrackingService, UserAccountService];
