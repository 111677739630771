/**
 * My Project
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Priority Session - started (on) / stopped (off). This is the RSE priority session with one OBD.
 */
export type SessionStatus = 'on' | 'off';

export const SessionStatus = {
    On: 'on' as SessionStatus,
    Off: 'off' as SessionStatus
};

