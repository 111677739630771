import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Vector as VectorSource } from 'ol/source';
import {
  Vector as VectorLayer,
  VectorImage as VectorImageLayer,
} from 'ol/layer';
import OlFeature from 'ol/Feature';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

import { nestedFireStationList } from 'app/shared/constant/fireStation';
import { hospitalList } from 'app/shared/constant/hospitals';
import { ColorData } from '@fuse/others/constant';
import { ICONS_PATH } from './trackingStyles';
import {
  OVERLAY_LAYER,
  TrackingService,
  VECTOR_LAYER,
} from './tracking.service';

import { fakeAmbulanceList } from 'app/shared/fakeData/ambulance';
import { AmbulanceStatus } from 'app/shared/constant/ambulanceStatus';
import { IconLoaderService } from '@fuse/services/icon-loader.service';
import { CommsNotificationService } from 'app/shared/services/monitoring/comms-notification.service';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import {
  OperationStatus,
  TrackingObdMap,
  TrackingService as OpenApiTrackingService,
  SessionStatus,
} from '@openapi/tps';
import { updateFeatureProperty } from '@helpers';
import { CommonService } from '@fuse/services/common.service';
import { containsCoordinate } from 'ol/extent';
import { Fill, Stroke, Text } from 'ol/style';
import createSquareFill from 'app/shared/helpers/squareFill';
import { AMBULANCE_LAYER_INDEX } from '@constant/layers';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export const AMBULANCE_LAYER = 'AMBULANCE';

export type AmbulanceData = {
  id?: string;
  callSign?: string;
  location: Location;
  status: string | number;
  destination: string;
  fireStation: string;
  incidentNo?: string;
};

export type AmbulanceStatusFilter =
  | 'allAmbulance'
  | 'offlineAmbulance'
  | 'onlineAmbulance'
  | 'activeAmbulance'
  | 'hideAmbulance';

export type AmbulanceLabelValues = 'deviceId' | 'callSign' | 'vehicleNumber';

const defaultStatus = 'onlineAmbulance';

export type AmbulanceHospitalFilter = {
  name: string;
  code: string;
  hospitalCode: string;
  selected: boolean;
};
export type AmbulanceFireStationFilter = {
  name: string;
  id: string;
  selected: boolean;
};
export type AmbulanceFireStationDivisionFilter = {
  division: string;
  fireStations: AmbulanceFireStationFilter[];
};

export type AmbulanceFilter = {
  status: AmbulanceStatusFilter;
  hospital: AmbulanceHospitalFilter[];
  fireStation: AmbulanceFireStationDivisionFilter[];
  reset?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AmbulanceTrackingService {
  private ambulanceFilterSource: BehaviorSubject<AmbulanceFilter>;
  public ambulanceFilter$: Observable<AmbulanceFilter>;
  constructor(
    private _trackingService: TrackingService,
    private _iconLoaderService: IconLoaderService,
    private _commsNotificationService: CommsNotificationService,
    private _commonService: CommonService,
    private _openApiTrackingService: OpenApiTrackingService,
    private _fuseSidebarService: FuseSidebarService,
    private _snackbar: MatSnackBar
  ) {
    // initialize filter
    this.ambulanceFireStationDivisionFilter = nestedFireStationList.map(
      fireStationDivision => {
        const fireStationListFilter: AmbulanceFireStationFilter[] =
          fireStationDivision.list.map(fireStation => ({
            name: fireStation.name,
            id: fireStation.id,
            selected: true,
          }));
        return {
          division: fireStationDivision.division,
          fireStations: fireStationListFilter,
        };
      }
    );
    this.ambulanceHospitalFilter = hospitalList.map(hospital => ({
      name: hospital.name,
      code: hospital.code,
      hospitalCode: hospital.hospitalCode,
      selected: false,
    }));

    this.ambulanceFilterSource = new BehaviorSubject({
      status: defaultStatus,
      fireStation: this.ambulanceFireStationDivisionFilter,
      hospital: this.ambulanceHospitalFilter,
    });
    this.defaultFilter = {
      status: defaultStatus,
      fireStation: JSON.parse(
        JSON.stringify(this.ambulanceFireStationDivisionFilter)
      ),
      hospital: JSON.parse(JSON.stringify(this.ambulanceHospitalFilter)),
    };
    this.ambulanceFilter$ = this.ambulanceFilterSource.asObservable();
  }

  defaultFilter: AmbulanceFilter;
  ambulanceSource: VectorSource;
  ambulanceMarkers = [];
  private ambulanceMarkerSubject: BehaviorSubject<any[]> = new BehaviorSubject(
    []
  );

  private offlineAmbulanceSubject: BehaviorSubject<number> =
    new BehaviorSubject(0);
  offlineAmbulance$: Observable<number> =
    this.offlineAmbulanceSubject.asObservable();
  private onlineAmbulanceSubject: BehaviorSubject<number> = new BehaviorSubject(
    0
  );
  onlineAmbulance$: Observable<number> =
    this.onlineAmbulanceSubject.asObservable();

  private countAmbulanceSubject: BehaviorSubject<number> = new BehaviorSubject(
    0
  );
  countAmbulance$: Observable<number> =
    this.countAmbulanceSubject.asObservable();

  ambulanceMarkers$: Observable<any[]> =
    this.ambulanceMarkerSubject.asObservable();
  ambulanceLayer: VectorLayer<any>;
  ambulanceStatusFilter: AmbulanceStatusFilter = defaultStatus;
  ambulanceFireStationDivisionFilter: Array<AmbulanceFireStationDivisionFilter> =
    [];
  ambulanceHospitalFilter: Array<AmbulanceHospitalFilter> = [];
  ambulanceLabelVisible = false;
  ambulanceLabelType: AmbulanceLabelValues = 'deviceId';

  printAmbulanceMarkers() {
    const ambulanceProperties = this.ambulanceMarkers.map(
      amb => amb?.getProperties() ?? undefined
    );
    console.log('TrackingAmbulance: ', ambulanceProperties);
  }

  transformAmbulanceMarkers(ambulanceMarkers = []) {
    const newList = ambulanceMarkers.map(marker => {
      const properties = marker?.getProperties();
      if (properties) {
        const { deviceId, status, description, callSign } = properties || {};
        const newObd = {
          deviceId,
          status,
          vehicleNumber: description,
          callSign,
        };
        return newObd;
      }
      return {};
    });

    return newList;
  }

  async initAmbulanceLayer(): Promise<VectorLayer<any>> {
    // define initial ambulance markers
    // this.ambulanceMarkers = fakeAmbulanceList.map(ambulance => {
    this.ambulanceMarkers = [].map(ambulance => {
      // this.ambulanceMarkers = [].map((ambulance) => {
      const ambulanceMarker = this.createAmbulanceMarker(ambulance);
      return ambulanceMarker;
    });
    const me = this;

    let ambList: TrackingObdMap[] = [];

    try {
      ambList = (await this._openApiTrackingService
        .trackingObdMapGet()
        .toPromise()) as TrackingObdMap[];

      if (ambList?.length > 0) {
        const filteredAmbList = ambList.filter(amb => !!amb.vehicleNumber); // remove ambulances without vehicle number
        const ambMarkers = filteredAmbList.map(amb => {
          const { operationStatus, ...rest } = amb;
          return this.createAmbulanceMarker({
            ...rest,
            status: operationStatus,
          });
        });
        this.ambulanceMarkers = [...this.ambulanceMarkers, ...ambMarkers];
        const newAmbulanceMarkers = this.transformAmbulanceMarkers(
          this.ambulanceMarkers
        );
        this.ambulanceMarkerSubject.next(newAmbulanceMarkers);
        this.countObd(newAmbulanceMarkers);
      }

      // console.log('openapi ambulance test', ambList);
    } catch (e) {
      console.log('Failed to fetch ambulance', e);
      ambList = [];
      this.ambulanceMarkers = [];
      this.ambulanceMarkerSubject.next([]);
      this.offlineAmbulanceSubject.next(0);
    }

    this.ambulanceSource = new VectorSource({
      features: this.ambulanceMarkers,
    });

    this.ambulanceLayer = new VectorLayer({
      source: this.ambulanceSource,
      // NOTE:  layer style will get overridden by feature style. below is example of layer style relying on external variable
      //        and using a style stored as a property. might not work if style is a style function.
      // style: function(feature, resolution) {
      //   const isVisible = feature.get('visible');
      //   const featureName = feature.get('name');
      //   // console.log(featureName, ': isvisible- ', isVisible ? 'true' : 'false')
      //   if (me.shouldDisplayMarker) {
      //     const featureStyle = feature.get('style');

      //     return featureStyle;
      //   }
      //   return undefined;
      // },

      // NOTE: style function using feature-based properties. need to use layer.changed() to trigger changes
      style: function (feature, resolution) {
        const isVisible = feature.get('visible');
        // const featureName = feature.get('description');
        const featureName = feature.get('deviceId');
        const featureColor = feature.get('color') ?? ColorData.eGray;
        if (isVisible) {
          const obdStyle = new Style({
            image: new Icon({
              // src: ICONS_PATH + feature.get('icon') + '.svg',
              img: me._iconLoaderService.getIcon(feature.get('icon')),
              imgSize: [24, 40],
              rotateWithView: true,
              rotation: (feature.get('heading') * Math.PI) / 180,
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              opacity: 1,
              scale: feature.get('scale'),
            }),
          });

          if (!me.getAmbulanceLabelVisibility()) {
            return [obdStyle];
          }

          const labelText = new Style({
            text: new Text({
              offsetY: -29,
              text: featureName,
              fill: new Fill({
                color: '#fff',
              }),
              padding: [5, 5, 5, 5],
              font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
            }),
          });

          const squareText = createSquareFill(featureName);
          const labelStyle = new Style({
            text: new Text({
              text: squareText,
              offsetY: -30,
              fill: new Fill({
                color: featureColor,
              }),
              textAlign: 'center',
              justify: 'center',
              textBaseline: 'middle',
              font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
              stroke: new Stroke({
                color: featureColor,
                width: 19,
              }),
            }),
          });
          const labelBorderStyle = new Style({
            text: new Text({
              text: squareText,
              offsetY: -30,
              fill: new Fill({
                color: '#000',
              }),
              textAlign: 'center',
              justify: 'center',
              textBaseline: 'middle',
              font: 'bold 11px "Muli", "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif',
              stroke: new Stroke({
                color: '#000',
                width: 21,
              }),
            }),
          });
          return [labelBorderStyle, labelStyle, labelText, obdStyle];
        }
        return undefined;
      },
      renderBuffer: 16,
      zIndex: AMBULANCE_LAYER_INDEX,
      className: VECTOR_LAYER + '-' + AMBULANCE_LAYER + '-markers',
    });
    this.ambulanceLayer.setZIndex(AMBULANCE_LAYER_INDEX);
    this.ambulanceLayer.setVisible(true);

    this.listenToFilter();
    this.listenAmbulance();

    return this.ambulanceLayer;
  }

  // is it possible to move this filter logic to layer style?
  // might need to rerun this filter function on every ambulance marker update because status / destination might change
  listenToFilter() {
    this.ambulanceFilterSource.subscribe((newFilter: AmbulanceFilter) => {
      const { status: statusFilter, fireStation, hospital } = newFilter;
      // console.log('Filter: ', statusFilter, fireStation, hospital);
      this.ambulanceLayer.getSource().forEachFeature(feature => {
        // console.log('ambulance layer feature', feature);
        // apply conditions here
        if (statusFilter === 'hideAmbulance') {
          feature.set('visible', false);
          return;
        }
        const ambulanceStatus: OperationStatus = feature.get('status');
        const ambulanceFireStation = feature.get('fireStation');
        const ambulanceDestination = feature.get('destination');

        const isActive = this.isAllowedFilter(
          statusFilter,
          hospital,
          fireStation,
          {
            status: ambulanceStatus,
            destination: ambulanceDestination,
            station: ambulanceFireStation,
          }
        );

        feature.set('visible', isActive);
      });
    });
  }

  isAllowedFilter(
    statusFilter: AmbulanceStatusFilter,
    hospitalFilter: AmbulanceHospitalFilter[],
    fireStationFilter: AmbulanceFireStationDivisionFilter[],
    data: {
      status: OperationStatus;
      destination: string;
      station: string;
    }
  ) {
    const { status, destination, station } = data;

    if (statusFilter === 'hideAmbulance') {
      return false;
    }

    if (statusFilter === 'onlineAmbulance' && status === 'offline') {
      return false;
    }

    if (statusFilter === 'offlineAmbulance' && status != 'offline') {
      return false;
    }

    const isStatusActive =
      statusFilter === 'allAmbulance' ||
      statusFilter === 'onlineAmbulance' ||
      statusFilter === 'offlineAmbulance'
        ? true
        : this.isAmbulanceActive(status);

    let isHospitalActive = hospitalFilter.every(
      hospital => hospital.selected === false
    );
    if (!isHospitalActive) {
      const currHospital = hospitalFilter.find(
        hospitalFilter =>
          hospitalFilter.code === destination ||
          hospitalFilter.hospitalCode === destination
      );
      if (currHospital) {
        isHospitalActive = currHospital.selected;
      }
    }

    // show ambulances without firestation
    let isFireStationActive = true;
    if (station) {
      isFireStationActive = fireStationFilter.some(fireStationDivision => {
        return fireStationDivision.fireStations.some(
          fireStationItem =>
            fireStationItem.id === station && fireStationItem.selected
        );
      });
    }

    const isActive = isStatusActive && isHospitalActive && isFireStationActive;

    return isActive;
  }

  updateAmbulanceStatusFilter(filter: AmbulanceStatusFilter) {
    this.ambulanceStatusFilter = filter;

    // toggle hospital filter if status filter is changed
    // if (this.ambulanceStatusFilter === 'onlineAmbulance') {
    //   this.ambulanceHospitalFilter = this.ambulanceHospitalFilter.map(
    //     hospital => {
    //       hospital.selected = false;
    //       return hospital;
    //     }
    //   );
    // }

    this.updateAmbulanceFilter();
  }

  updateAllAmbulanceHospitalFilter(selected) {
    this.ambulanceHospitalFilter = this.ambulanceHospitalFilter.map(
      hospital => {
        hospital.selected = selected;
        return hospital;
      }
    );
    this.updateAmbulanceFilter();
  }

  updateAmbulanceHospitalFilter(filter: AmbulanceHospitalFilter) {
    const { code: filterCode, selected: filterSelected } = filter;
    this.ambulanceHospitalFilter = this.ambulanceHospitalFilter.map(
      (hospital: AmbulanceHospitalFilter) => {
        if (hospital.code === filterCode) {
          hospital.selected = filterSelected;
        }
        return hospital;
      }
    );

    // toggle ambulance status filter if hospital filter is changed
    if (this.ambulanceStatusFilter !== 'allAmbulance') {
      this.ambulanceStatusFilter = 'activeAmbulance';
    }

    this.updateAmbulanceFilter();
  }

  updateAmbulanceFireStationFilter(
    division: string,
    filter: AmbulanceFireStationFilter
  ) {
    const { id: filterId, selected: filterSelected } = filter;
    this.ambulanceFireStationDivisionFilter =
      this.ambulanceFireStationDivisionFilter.map(fireStationDivision => {
        if (division === fireStationDivision.division) {
          fireStationDivision.fireStations =
            fireStationDivision.fireStations.map(fireStation => {
              if (filterId === fireStation.id) {
                fireStation.selected = filterSelected;
              }
              return fireStation;
            });
        }
        return fireStationDivision;
      });
    this.updateAmbulanceFilter();
  }

  updateAllAmbulanceFireStationFilter(selected: boolean) {
    this.ambulanceFireStationDivisionFilter =
      this.ambulanceFireStationDivisionFilter.map(division => {
        division.fireStations = division.fireStations.map(fireStation => {
          fireStation.selected = selected;
          return fireStation;
        });
        return division;
      });
    this.updateAmbulanceFilter();
  }

  updateAmbulanceFireStationDivisionFilter(
    division: string,
    selected: boolean
  ) {
    this.ambulanceFireStationDivisionFilter =
      this.ambulanceFireStationDivisionFilter.map(fireStationDivision => {
        if (division === fireStationDivision.division) {
          fireStationDivision.fireStations =
            fireStationDivision.fireStations.map(fireStation => {
              fireStation.selected = selected;
              return fireStation;
            });
        }
        return fireStationDivision;
      });
    if (this.ambulanceStatusFilter === 'hideAmbulance') {
      this.ambulanceStatusFilter = 'onlineAmbulance';
    }

    this.updateAmbulanceFilter();
  }

  updateAmbulanceFilter(isReset = false) {
    this.ambulanceFilterSource.next({
      status: this.ambulanceStatusFilter,
      fireStation: this.ambulanceFireStationDivisionFilter,
      hospital: this.ambulanceHospitalFilter,
      reset: isReset,
    });
  }

  resetAmbulanceFilter() {
    const newFilter: AmbulanceFilter = JSON.parse(
      JSON.stringify(this.defaultFilter)
    );
    this.ambulanceStatusFilter = newFilter.status;
    this.ambulanceHospitalFilter = newFilter.hospital;
    this.ambulanceFireStationDivisionFilter = newFilter.fireStation;
    this.updateAmbulanceFilter(true);
  }

  addMovingMarker(
    name: string,
    description: string,
    longitude: number,
    latitude: number,
    heading: number,
    color: ColorData,
    icon: string,
    scale = 0.75,
    properties?: object
  ) {
    const marker = new OlFeature({
      type: 'icon',
      geometry: new Point(
        transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')
      ),
      name,
      description,
      color,
      icon,
      scale,
      longitude,
      latitude,
      heading,
      ...properties,
    });

    // NOTE: static feature style example. feature style overrides layer style.
    // const iconProps = { heading: heading * Math.PI / 180, rotateWithView: true }
    // const imageStyle = createCommonIconStyle(icon, scale, iconProps);
    // const markerStyle = new Style({
    //   image: imageStyle,
    // });
    // marker.set('style', markerStyle);

    // NOTE: use style function for dynamic icon properties.
    // const iconStyleFunction = function(feature, resolution) {
    //   const isVisible = feature.get('visible');
    //   if (isVisible) {
    //     return new Style({
    //       image: new Icon({
    //           src: ICONS_PATH + feature.get('icon') + '.svg',
    //           rotateWithView: true,
    //           heading: feature.get('heading') * Math.PI / 180,
    //           anchor: [0.5, 0.5],
    //           anchorXUnits: 'fraction', anchorYUnits: 'fraction',
    //           opacity: 1,
    //           scale: feature.get('scale')
    //       })
    //   });
    //   }
    //   return undefined;
    // };
    // marker.setStyle(iconStyleFunction);

    // NOTE: example for style function set in feature.
    // const me = this;
    // const styleFunction = function(feature, resolution) {
    //   const isVisible = feature.get('visible');
    //   const featureName = feature.get('name');
    //   // if (isVisible) {
    //   if (me.shouldDisplayMarker) {
    //     const featureStyle = feature.get('style');
    //     return featureStyle;
    //   }
    //   return undefined;
    // }
    // marker.setStyle(styleFunction);

    return marker;
  }

  createAmbulanceMarker(ambulance) {
    const {
      vehicleNumber,
      deviceId,
      location,
      callSign,
      destination,
      fireStation,
      incidentNo,
      status,
      sessionStatus,
      visible,
    } = ambulance || {};
    const { latitude, longitude, heading } = location || {};

    // init status
    const { statusColor, icon } = this.getAmbulanceStyle(status, sessionStatus);

    const properties = {
      callSign,
      destination,
      fireStation,
      incidentNo,
      status,
      visible,
      deviceId,
    };
    const featureName =
      VECTOR_LAYER + '-' + AMBULANCE_LAYER + '-' + vehicleNumber;
    const ambulanceMarker = this.addMovingMarker(
      featureName,
      vehicleNumber,
      longitude,
      latitude,
      heading,
      statusColor,
      icon,
      1.15,
      properties
    );

    return ambulanceMarker;
  }

  getAmbulanceStyle(status, sessionStatus: SessionStatus) {
    // console.log('OBD status style', status, sessionStatus);
    const formattedSessionStatus = sessionStatus?.toLowerCase() ?? 'off';
    let statusColor = ColorData.eBlue;
    let icon = 'navigation-blue';
    switch (status) {
      // case 'activated':
      //   statusColor = ColorData.eGreen;
      //   icon = 'navigation-green';
      //   break;
      case 'tracking':
      case 'online':
      case AmbulanceStatus.base:
      case AmbulanceStatus.movingOut:
      case AmbulanceStatus.return:
        statusColor = ColorData.eBlue;
        icon = 'navigation-blue';
        // if (formattedSessionStatus === 'on') {
        //   statusColor = ColorData.eGreen;
        //   icon = 'navigation-green';
        // } else {
        //   statusColor = ColorData.eBlue;
        //   icon = 'navigation-blue';
        // }
        break;
      case AmbulanceStatus.offDuty:
      case AmbulanceStatus.outOfService:
      case AmbulanceStatus.offline:
        statusColor = ColorData.eGray;
        icon = 'navigation-gray';
        break;
      case AmbulanceStatus.atHospital:
      case AmbulanceStatus.dispatched:
      case AmbulanceStatus.patient:
      case AmbulanceStatus.post:
      case AmbulanceStatus.responding:
      case AmbulanceStatus.scene:
      case AmbulanceStatus.toHospital:
      case 'activated':
      case 'auto':
      case 'manual':
        if (formattedSessionStatus === 'on') {
          statusColor = ColorData.eGreen;
          icon = 'navigation-green';
        } else {
          statusColor = ColorData.eRed;
          icon = 'navigation-red';
        }
        break;
      default:
        statusColor = ColorData.eGray;
        icon = 'navigation-gray';
    }
    return { statusColor, icon };
  }

  isAmbulanceActive(status: string): boolean {
    switch (status) {
      case AmbulanceStatus.atHospital:
      case AmbulanceStatus.base:
      case AmbulanceStatus.dispatched:
      case AmbulanceStatus.movingOut:
      case AmbulanceStatus.patient:
      case AmbulanceStatus.post:
      case AmbulanceStatus.responding:
      case AmbulanceStatus.return:
      case AmbulanceStatus.scene:
      case AmbulanceStatus.toHospital:
      case 'auto':
      case 'manual':
        return true;
      default:
        return false;
    }
  }

  findAmbulanceFeature(ambulance: string) {
    const features = this.ambulanceLayer.getSource().getFeatures();

    const currFeature =
      features.find(feature => {
        const featureName = feature.get('description');
        // console.log('feature', featureName, ambulance);
        return featureName === ambulance;
      }) ?? null;

    return currFeature;
  }

  /** MQTT Listener */
  ambulanceSubscription: Subscription;
  listenAmbulance() {
    this.ambulanceSubscription =
      this._trackingService.ambulanceUpdate$.subscribe(
        (data: TrackingObdMap) => {
          const {
            vehicleNumber,
            deviceId,
            callSign,
            destination: currDestination,
            division,
            fireStation,
            location,
            operationStatus: currOperationStatus,
            sessionStatus: currSessionStatus,
            incidentNo,
          } = data;

          const { longitude, latitude, heading } = location || {};

          //  TODO: replace callsign check with VEHICLE NUMBER
          const ambulanceFeature = this.findAmbulanceFeature(vehicleNumber);

          if (ambulanceFeature) {
            // console.log(
            //   'Update Ambulance',
            //   vehicleNumber,
            //   data,
            //   ambulanceFeature
            // );

            const prevOperationStatus = ambulanceFeature.get('status');
            const prevDestination = ambulanceFeature.get('destination');

            const destination =
              currDestination !== null ? currDestination : prevDestination;

            const operationStatus =
              currOperationStatus !== null
                ? currOperationStatus?.toLowerCase()
                : prevOperationStatus;

            const sessionStatus: SessionStatus =
              currSessionStatus !== null ? currSessionStatus : 'off';

            const isActive = this.isAllowedFilter(
              this.ambulanceStatusFilter,
              this.ambulanceHospitalFilter,
              this.ambulanceFireStationDivisionFilter,
              {
                status: operationStatus,
                destination: destination,
                station: fireStation,
              }
            );

            if (location) {
              ambulanceFeature.setGeometry(
                new Point(
                  transform(
                    [Number(longitude), Number(latitude)],
                    'EPSG:4326',
                    'EPSG:3857'
                  )
                )
              );
              updateFeatureProperty(ambulanceFeature, 'latitude', latitude);
              updateFeatureProperty(ambulanceFeature, 'longitude', longitude);
              updateFeatureProperty(ambulanceFeature, 'heading', heading ?? 0);
            }

            updateFeatureProperty(ambulanceFeature, 'status', operationStatus);
            updateFeatureProperty(
              ambulanceFeature,
              'sessionStatus',
              sessionStatus
            );

            // replace with obd datestatus
            const dateStatus = moment()
              .utcOffset(environment.timezone)
              .toISOString();

            if (
              prevOperationStatus !== 'offline' &&
              prevOperationStatus !== 'online' &&
              operationStatus?.toLowerCase() === 'offline'
            ) {
              const snackbarRef =
                this._commsNotificationService.obdOfflineAlert(
                  deviceId ?? vehicleNumber,
                  incidentNo,
                  dateStatus,
                  true,
                  'Check'
                );
              snackbarRef.onAction().subscribe(() => {
                this.panToAmbulance(vehicleNumber);
              });
            }

            // console.log('tps status', tpsStatus, status);
            // let icon, statusColor;
            const { icon: newIcon, statusColor: newStatusColor } =
              this.getAmbulanceStyle(operationStatus, sessionStatus);
            const icon = newIcon;
            const statusColor = newStatusColor;

            updateFeatureProperty(ambulanceFeature, 'icon', icon);
            updateFeatureProperty(ambulanceFeature, 'color', statusColor);
            updateFeatureProperty(ambulanceFeature, 'destination', destination);
            updateFeatureProperty(ambulanceFeature, 'callSign', callSign);
            updateFeatureProperty(ambulanceFeature, 'fireStation', fireStation);
            updateFeatureProperty(ambulanceFeature, 'division', division);
            updateFeatureProperty(ambulanceFeature, 'incidentNo', incidentNo);
            // if (operationStatus === 'online') {
            //   ambulanceFeature.set('incidentNo', '');
            //   ambulanceFeature.set('destination', '');
            //   ambulanceFeature.set('callSign', '');
            // }
            updateFeatureProperty(ambulanceFeature, 'visible', isActive);
            updateFeatureProperty(ambulanceFeature, 'deviceId', deviceId);

            if (currOperationStatus?.toLowerCase() !== prevOperationStatus) {
              const newAmbulanceMarkers = this.transformAmbulanceMarkers(
                this.ambulanceMarkers
              );
              this.ambulanceMarkerSubject.next(newAmbulanceMarkers);
              this.countObd(newAmbulanceMarkers);
            }
          } else {
            // create ambulance feature
            if (!vehicleNumber) return;
            // console.log('Create Ambulance', vehicleNumber, data);
            const operationStatus = currOperationStatus;
            const destination = currDestination;
            const sessionStatus = currSessionStatus;
            const isActive = this.isAllowedFilter(
              this.ambulanceStatusFilter,
              this.ambulanceHospitalFilter,
              this.ambulanceFireStationDivisionFilter,
              {
                status: operationStatus,
                destination,
                station: fireStation,
              }
            );

            const ambulance = {
              id: vehicleNumber,
              callSign,
              vehicleNumber,
              location: {
                longitude,
                latitude,
                heading: heading ?? 0,
              },
              destination: destination ?? undefined,
              fireStation,
              incidentNo: incidentNo,
              status: operationStatus,
              sessionStatus,
              visible: isActive,
              deviceId,
            };

            const ambulanceMarker = this.createAmbulanceMarker(ambulance);

            // console.log('new ambulance: ', ambulanceMarker);

            this.ambulanceSource.addFeature(ambulanceMarker);
            this.ambulanceMarkers.push(ambulanceMarker);
            const newAmbulanceMarkers = this.transformAmbulanceMarkers(
              this.ambulanceMarkers
            );
            this.ambulanceMarkerSubject.next(newAmbulanceMarkers);
            this.countObd(newAmbulanceMarkers);
          }
        }
      );
  }

  panToAmbulance(id: string) {
    const ambulanceFeature = this.findAmbulanceFeature(id);
    if (ambulanceFeature) {
      const ambCoord = ambulanceFeature.getGeometry().getCoordinates();

      const currExtent = this._trackingService.mapBoundingExtent;
      if (containsCoordinate(currExtent, ambCoord)) {
        const mapView = this._trackingService.getMapView;
        const point = new Point(ambCoord);
        const isIncidentListPanelOpen =
          this._fuseSidebarService.getSidebar('trackingPanel')?.opened ?? false;
        const padding = isIncidentListPanelOpen ? 700 : 0;

        if (!ambulanceFeature.get('visible')) {
          this.resetAmbulanceFilter();
          if (ambulanceFeature.get('status') === 'offline') {
            this.updateAmbulanceStatusFilter('allAmbulance');
          }
        }

        mapView.fit(point, {
          padding: [0, 0, 0, padding], // sidebar panel width
          maxZoom: 18,
          duration: 2000,
        });
      } else {
        console.log('Error: Ambulance is not within map boundaries', ambCoord);

        this._snackbar.open(
          `Failed to locate ambulance ${id}. OBD location is unknown.`,
          undefined,
          {
            panelClass: 'custom-snack-bar-panel-error',
            duration: 3000,
          }
        );
      }
    }
  }

  getAmbulanceLabelVisibility() {
    return this.ambulanceLabelVisible;
  }

  setLabelVisible(value: boolean) {
    this.ambulanceLabelVisible = value;
    this.ambulanceLayer.changed();
  }

  setLabelType(value: AmbulanceLabelValues) {
    this.ambulanceLabelType = value;
  }

  cleanup() {
    this.ambulanceSubscription?.unsubscribe();
    this.ambulanceSubscription = undefined;
  }

  countObd(ambulanceList: any[]) {
    const allObd = this.countAllObd(ambulanceList);
    const onlineCount = this.countOnlineObd(ambulanceList);
    const offlineCount = this.countOfflineObd(ambulanceList);
    this.offlineAmbulanceSubject.next(offlineCount);
    this.onlineAmbulanceSubject.next(onlineCount);
    this.countAmbulanceSubject.next(allObd);
  }

  countOfflineObd(ambulanceList: any[]): number {
    if (!ambulanceList) return 0;
    const filteredList = ambulanceList.filter(amb => amb?.status === 'offline');
    const count = filteredList.length;
    return count ?? 0;
  }

  countAllObd(ambulanceList: any[]): number {
    if (!ambulanceList) return 0;
    const count = ambulanceList.length;
    return count ?? 0;
  }

  countOnlineObd(ambulanceList: any[]): number {
    if (!ambulanceList) return 0;
    const filteredList = ambulanceList.filter(
      amb =>
        amb?.status === 'online' ||
        amb?.status === 'auto' ||
        amb?.status === 'manual'
    );
    const count = filteredList.length;
    return count ?? 0;
  }
}
