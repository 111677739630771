const intersections = {
  '1': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 1,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '2': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 2,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '3': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 3,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '4': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 4,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '5': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 5,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '6': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 6,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '7': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 7,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '8': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 8,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '9': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 9,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '10': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 10,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '11': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 11,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '12': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 12,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '13': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 13,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '14': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 14,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '15': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 15,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '16': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 16,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '17': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 17,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '18': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 18,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '19': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 19,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '20': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 20,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '21': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 21,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '22': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 22,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '23': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 23,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '24': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 24,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '25': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 25,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '26': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 26,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '27': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 27,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '28': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 28,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '29': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 29,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '30': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 30,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '31': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 31,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '32': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 32,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '33': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 33,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '34': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 34,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '35': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 35,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '36': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 36,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '37': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 37,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '38': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 38,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '39': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 39,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '40': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 40,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '41': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 41,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '42': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 42,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '43': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 43,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '44': {
    hospital: 'TOD',
    code: 'TOD',
    name: '',
    fullName: '',
    intersectionId: 44,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '3': {
        approach_id: 3,
        approach_type: 2,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '4': {
        approach_id: 4,
        approach_type: 3,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'approach from xx road',
        fullName: 'approach from xx road',
      },
    },
  },
  '8331': {
    hospital: 'NTFGH',
    hospitalCode: 'H9',
    junction: 'J4',
    junctionCode: 4,
    code: 'H9-J4-I8331-B126',
    name: 'Jurong East Street 11/Boon Lay Way',
    fullName: 'NTFGH, H9-J4-I8331-B126, Jurong East Street 11/Boon Lay Way',
    intersectionId: 8331,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'toh guan rd',
        direction: 'D1',
        fullName: 'D1 - ingress approach - toh guan rd',
      },
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'boon lay way',
        direction: 'D2',
        fullName: 'D2 - ingress approach - boon lay way',
      },
      '12': {
        approach_id: 12,
        approach_type: 3,
        name: 'boon lay way',
        direction: 'D4',
        fullName: 'D4 - egress approach - boon lay way',
      },
    },
  },
  '8332': {
    hospital: 'NTFGH',
    hospitalCode: 'H9',
    junction: 'J3',
    junctionCode: 3,
    code: 'H9-J3-I8332-B1124',
    name: 'Toh Guan Rd/Toh Guan Rd E',
    fullName: 'NTFGH, H9-J3-I8332-B1124, Toh Guan Rd/Toh Guan Rd E',
    intersectionId: 8332,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'toh guan rd',
        direction: 'D1',
        fullName: 'D1 - ingress approach - toh guan rd',
      },
      '3': {
        approach_id: 3,
        approach_type: 1,
        name: 'toh guan rd e',
        direction: 'D2',
        fullName: 'D2 - ingress approach - toh guan rd e',
      },
      '12': {
        approach_id: 12,
        approach_type: 3,
        name: 'jurong gateway rd',
        direction: 'D4',
        fullName: 'D4 - egress approach - jurong gateway rd',
      },
    },
  },
  '8338': {
    hospital: 'NTFGH',
    hospitalCode: 'H9',
    junction: 'J2',
    junctionCode: 2,
    code: 'H9-J2-I8338-B2121',
    name: 'Jurong East street 21/Jurong Gateway Rd',
    fullName:
      'NTFGH, H9-J2-I8338-B2121, Jurong East street 21/Jurong Gateway Rd',
    intersectionId: 8338,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'jurong gateway rd',
        direction: 'D1',
        fullName: 'D1 - ingress approach - jurong gateway rd',
      },
      '8': {
        approach_id: 8,
        approach_type: 1,
        name: 'Jurong East Street 21',
        direction: 'D4',
        fullName: 'D4 - ingress approach - Jurong East Street 21',
      },
      '11': {
        approach_id: 11,
        approach_type: 2,
        name: 'jurong gateway rd',
        direction: 'D3',
        fullName: 'D3 - egress approach - jurong gateway rd',
      },
    },
  },
  '8343': {
    hospital: 'NTFGH',
    hospitalCode: 'H9',
    junction: 'J1',
    junctionCode: 1,
    code: 'H9-J1-I8343-B2223',
    name: 'Boon Lay Way/Jurong Gateway Road',
    fullName: 'NTFGH, H9-J1-I8343-B2223, BOON LAY WAY/JURONG GATEWAY ROAD',
    intersectionId: 8343,
    approach: {
      '6': {
        approach_id: 6,
        approach_type: 1,
        name: 'jurong gateway road',
        direction: 'D3',
        fullName: 'D3 - ingress approach - jurong gateway road',
      },
      '8': {
        approach_id: 8,
        approach_type: 1,
        name: 'boon lay way',
        direction: 'D4',
        fullName: 'D4 - ingress approach - boon lay way',
      },
      '10': {
        approach_id: 10,
        approach_type: 3,
        name: 'boon lay way',
        direction: 'D2',
        fullName: 'D2 - egress approach - boon lay way',
      },
    },
  },
  '1204': {
    hospital: 'KTPH',
    hospitalCode: 'H2',
    junction: 'J1',
    junctionCode: 1,
    code: 'H2-J1-I1204-B949',
    name: 'Yishun Ave 2/Yishun Central 1',
    fullName: 'KTPH, H2-J1-I1204-B949, YISHUN AVE 2/YISHUN CENTRAL 1',
    intersectionId: 1204,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'Yishun Ave 2',
        direction: 'D1',
        fullName: 'D1 - ingress approach - Yishun Ave 2',
      },
      '11': {
        approach_id: 11,
        approach_type: 3,
        name: 'Yishun Ave 2',
        direction: 'D3',
        fullName: 'D3 - egress approach - Yishun Ave 2',
      },
    },
  },
  '1203': {
    hospital: 'KTPH',
    hospitalCode: 'H2',
    junction: 'J2',
    junctionCode: 2,
    code: 'H2-J2-I1203-B633',
    name: 'Yishun Ave 3/Yishun Ave 2/Yishun Central',
    fullName:
      'KTPH, H2-J2-I1203-B633, Yishun Ave 3/Yishun Ave 2/Yishun Central',
    intersectionId: 1203,
    approach: {
      '5': {
        approach_id: 5,
        approach_type: 1,
        name: 'Yishun Ave 2',
        direction: 'D3',
        fullName: 'D3 - ingress approach - Yishun Ave 2',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'Yishun Ave 3',
        direction: 'D4',
        fullName: 'D4 - ingress approach - Yishun Ave 3',
      },
      '10': {
        approach_id: 10,
        approach_type: 3,
        name: 'Yishun Central',
        direction: 'D2',
        fullName: 'D2 - egress approach - Yishun Central',
      },
    },
  },
  '1202': {
    hospital: 'KTPH',
    hospitalCode: 'H2',
    junction: 'J3',
    junctionCode: 3,
    code: 'H2-J3-I1202-B719',
    name: 'Yishun Ring Rd/Yishun Ave 2',
    fullName: 'KTPH, H2-J3-I1202-B719, Yishun Ring Rd/Yishun Ave 2',
    intersectionId: 1202,
    approach: {
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'Yishun Ring Rd',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Yishun Ring Rd',
      },
      '6': {
        approach_id: 6,
        approach_type: 1,
        name: 'Yishun Ave 2',
        direction: 'D3',
        fullName: 'D3 - ingress approach - Yishun Ave 2',
      },
      '8': {
        approach_id: 8,
        approach_type: 1,
        name: 'Yishun Ring Rd',
        direction: 'D4',
        fullName: 'D4 - ingress approach - Yishun Ring Rd',
      },
      '9': {
        approach_id: 9,
        approach_type: 3,
        name: 'Yishun Ave 2',
        direction: 'D1',
        fullName: 'D1 - egress approach - Yishun Ave 2',
      },
    },
  },
  '1321': {
    hospital: 'KTPH',
    hospitalCode: 'H2',
    junction: 'J4',
    junctionCode: 4,
    code: 'H2-J4-I1321-B919',
    name: 'Yishun Central / Yishun Central 2 / Yishun Street 61',
    fullName:
      'KTPH, H2-J4-I1321-B919, Yishun Central / Yishun Central 2 / Yishun Street 61',
    intersectionId: 1321,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'Yishun Central 2',
        direction: 'D1',
        fullName: 'D1 - ingress approach - Yishun Central 2',
      },
      '5': {
        approach_id: 5,
        approach_type: 1,
        name: 'Yishun Street 61',
        direction: 'D3',
        fullName: 'D3 - ingress approach - Yishun Street 61',
      },
      '7': {
        approach_id: 7,
        approach_type: 1,
        name: 'Yishun Central',
        direction: 'D4',
        fullName: 'D4 - ingress approach - Yishun Central',
      },
      '10': {
        approach_id: 10,
        approach_type: 3,
        name: 'Yishun Central',
        direction: 'D2',
        fullName: 'D2 - egress approach - Yishun Central',
      },
    },
  },
  '1324': {
    hospital: 'KTPH',
    hospitalCode: 'H2',
    junction: 'J5',
    junctionCode: 5,
    code: 'H2-J5-I1324-B2230',
    name: 'Yishun Central',
    fullName: 'KTPH, H2-J5-I1324-B2230, Yishun Central',
    intersectionId: 1324,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'Yishun Central',
        direction: 'D1',
        fullName: 'D1 - ingress approach - Yishun Central',
      },
      '11': {
        approach_id: 11,
        approach_type: 3,
        name: 'Yishun Central',
        direction: 'D2',
        fullName: 'D2 - egress approach - Yishun Central',
      },
    },
  },
  '1322': {
    hospital: 'KTPH',
    hospitalCode: 'H2',
    junction: 'J6',
    junctionCode: 6,
    code: 'H2-J6-I1322-B868',
    name: 'Yishun Central/Yishun Ave 4',
    fullName: 'KTPH, H2-J6-I1322-B868, Yishun Central/Yishun Ave 4',
    intersectionId: 1322,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'Yishun Central',
        direction: 'D1',
        fullName: 'D1 - ingress approach - Yishun Central',
      },
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'Yishun Ave 4',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Yishun Ave 4',
      },
      '11': {
        approach_id: 11,
        approach_type: 3,
        name: 'Yishun Central',
        direction: 'D3',
        fullName: 'D3 - egress approach - Yishun Central',
      },
    },
  },
  '11423': {
    hospital: 'CGH',
    hospitalCode: 'H5',
    junction: 'J1',
    junctionCode: 1,
    code: 'H2-J6-I11423-B364',
    name: 'Simei Street 1/Simei Street 3',
    fullName: 'CGH, H5-J6-I11423-B364, Simei Street 1/Simei Street 3',
    intersectionId: 11423,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'Simei Street 3',
        direction: 'D1',
        fullName: 'D1 - ingress approach - Simei Street 3',
      },
      '8': {
        approach_id: 8,
        approach_type: 1,
        name: 'Simei Street 3',
        direction: 'D3',
        fullName: 'D3 - ingress approach - Simei Street 3',
      },
      '11': {
        approach_id: 11,
        approach_type: 3,
        name: 'Simei Street 1',
        direction: 'D2',
        fullName: 'D2 - egress approach - Simei Street 1',
      },
    },
  },
  '11427': {
    hospital: 'CGH',
    hospitalCode: 'H5',
    junction: 'J2',
    junctionCode: 2,
    code: 'H5-J2-I11427-B2026',
    name: 'Simei Street 3/Simei Street 3',
    fullName: 'CGH, H5-J2-I11427-B2026, Simei Street 3/Simei Street 3',
    intersectionId: 11427,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'Simei Street 3',
        direction: 'D1',
        fullName: 'D1 - ingress approach - Simei Street 3',
      },
      '6': {
        approach_id: 6,
        approach_type: 1,
        name: 'Simei Street 3',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Simei Street 3',
      },
      '12': {
        approach_id: 12,
        approach_type: 3,
        name: 'Simei Street 3',
        direction: 'D3',
        fullName: 'D3 - egress approach - Simei Street 3',
      },
    },
  },
  '11404': {
    hospital: 'CGH',
    hospitalCode: 'H5',
    junction: 'J3',
    junctionCode: 3,
    code: 'H5-J3-I11404-B1289',
    name: 'Simei Avenue/Simei Street 3',
    fullName: 'CGH, H5-J3-I11404-B1289, Simei Avenue/Simei Street 3',
    intersectionId: 11404,
    approach: {
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'Simei Avenue',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Simei Avenue',
      },
      '9': {
        approach_id: 3,
        approach_type: 9,
        name: 'Simei Street 3',
        direction: 'D1',
        fullName: 'D1 - egress approach - Simei Street 3',
      },
    },
  },
  '11403': {
    hospital: 'CGH',
    hospitalCode: 'H5',
    junction: 'J4',
    junctionCode: 4,
    code: 'H5-J4-I11403-B364',
    name: 'Simei Avenue/Upper Changi Rd',
    fullName: 'CGH, H5-J4-I11403-B364, Simei Avenue/Upper Changi Rd',
    intersectionId: 11403,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'Upper Changi Rd',
        direction: 'D1',
        fullName: 'D1 - ingress approach - Upper Changi Rd',
      },
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'Simei Avenue',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Simei Avenue',
      },
      '12': {
        approach_id: 12,
        approach_type: 3,
        name: 'Simei Avenue',
        direction: 'D4',
        fullName: 'D4 - egress approach - Simei Avenue',
      },
    },
  },
  '3533': {
    hospital: 'NUH',
    hospitalCode: 'H6',
    junction: 'J1',
    junctionCode: 1,
    code: 'H6-J1-I3533-B2263',
    name: 'South Buona Vista Rd/Kent Ridge Rd/Stockport Rd',
    fullName:
      'NUH, H6-J1-I3533-B2263, South Buona Vista Rd/Kent Ridge Rd/Stockport Rd',
    intersectionId: 3533,
    approach: {
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'Stockport Rd',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Stockport Rd',
      },
      '6': {
        approach_id: 6,
        approach_type: 1,
        name: 'South Buona Vista Rd',
        direction: 'D3',
        fullName: 'D3 - ingress approach - South Buona Vista Rd',
      },
      '8': {
        approach_id: 8,
        approach_type: 1,
        name: 'Kent Ridge Rd',
        direction: 'D4',
        fullName: 'D4 - ingress approach - Kent Ridge Rd',
      },
      '9': {
        approach_id: 9,
        approach_type: 3,
        name: 'South Buona Vista Rd',
        direction: 'D1',
        fullName: 'D1 - egress approach - South Buona Vista Rd',
      },
    },
  },
  '3532': {
    hospital: 'NUH',
    hospitalCode: 'H6',
    junction: 'J2',
    junctionCode: 2,
    code: 'H6-J2-I3532-B1271',
    name: 'South Buona Vista Rd/Science Park Dr',
    fullName: 'NUH, H6-J2-I3532-B1271, South Buona Vista Rd/Science Park Dr',
    intersectionId: 3532,
    approach: {
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'Science Park Dr',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Science Park Dr',
      },
      '6': {
        approach_id: 6,
        approach_type: 1,
        name: 'South Buona Vista Rd',
        direction: 'D3',
        fullName: 'D3 - ingress approach - South Buona Vista Rd',
      },
      '9': {
        approach_id: 9,
        approach_type: 3,
        name: 'South Buona Vista Rd',
        direction: 'D1',
        fullName: 'D1 - egress approach - South Buona Vista Rd',
      },
    },
  },
  '3531': {
    hospital: 'NUH',
    hospitalCode: 'H6',
    junction: 'J3',
    junctionCode: 3,
    code: 'H6-J3-I3531-B971',
    name: 'South Buona Vista Rd/Lower Kent Ridge Rd',
    fullName: 'NUH, H6-J3-I3531-B971, South Buona Vista Rd/Lower Kent Ridge Rd',
    intersectionId: 3531,
    approach: {
      '2': {
        approach_id: 2,
        approach_type: 1,
        name: 'South Buona Vista Rd',
        direction: 'D1',
        fullName: 'D1 - ingress approach - South Buona Vista Rd',
      },
      '4': {
        approach_id: 4,
        approach_type: 1,
        name: 'AYE Exit 8',
        direction: 'D2',
        fullName: 'D2 - ingress approach - AYE Exit 8',
      },
      '12': {
        approach_id: 12,
        approach_type: 3,
        name: 'Lower Kent Ridge Rd',
        direction: 'D4',
        fullName: 'D4 - ingress approach - Lower Kent Ridge Rd',
      },
    },
  },
  '3521': {
    hospital: 'NUH',
    hospitalCode: 'H6',
    junction: 'J4',
    junctionCode: 4,
    code: 'H6-J4-I3521-B972',
    name: 'Buona Vista Flyover/North Buona Vista Rd',
    fullName: 'NUH, H6-J4-I3521-B972, Buona Vista Flyover/North Buona Vista Rd',
    intersectionId: 3521,
    approach: {
      '1': {
        approach_id: 1,
        approach_type: 1,
        name: 'North Buona Vista Rd',
        direction: 'D1',
        fullName: 'D1 - ingress approach - North Buona Vista Rd',
      },
      '8': {
        approach_id: 8,
        approach_type: 1,
        name: 'AYE Exit 8',
        direction: 'D3',
        fullName: 'D3 - ingress approach - AYE Exit 8',
      },
      '11': {
        approach_id: 11,
        approach_type: 3,
        name: 'Buona Vista Flyover',
        direction: 'D2',
        fullName: 'D2 - ingress approach - Buona Vista Flyover',
      },
    },
  },
  // temporary CGH code
  '9997': {
    hospital: 'CGH',
    hospitalCode: 'H5',
    junction: 'J3',
    junctionCode: 2,
    // code: 'H9-J1-I8343-B2223',
    // name: '',
    intersectionId: 9998,
  },
  '9998': {
    hospital: 'CGH',
    hospitalCode: 'H5',
    junction: 'J2',
    junctionCode: 2,
    // code: 'H9-J1-I8343-B2223',
    // name: '',
    intersectionId: 9998,
  },
  '9999': {
    hospital: 'CGH',
    hospitalCode: 'H5',
    junction: 'J1',
    junctionCode: 1,
    // code: 'H9-J1-I8343-B2223',
    // name: '',
    intersectionId: 9999,
  },
};

export default intersections;
